import React from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";

const DropDown = () => {
  return (
    <div>
      <Container>
        <Wrap>
          <Tex to="/register">
            <h4>Register</h4>
          </Tex>
        </Wrap>
      </Container>
    </div>
  );
};

export default DropDown;

const Wrap = styled.div``;

const Tex = styled(NavLink)`
  cursor: pointer;
  transition: all 350ms;
  margin-left: 22px;
  text-decoration: none;
  h4 {
    color: white;
  }
`;

const Container = styled.div`
  top: 60px;
  right: 5px;
  position: absolute;
  width: 100px;
  height: 100px;
  border: 1px solid white;
  border-radius: 10px;
  background-color: transparent;
  display: flex;
  justify-content: center;
  //   align-items: center;
  /* background-color: #0852f1; */
`;
