import React from "react";
import styled from "styled-components";
import images from "./Assets/tecback.png";

const Submit = () => {
  return <Container></Container>;
};

export default Submit;

const Container = styled.div`
  /* position: absolute; */
  width: 100%;
  height: 100vh;
  background-color: rgb(212, 20, 90);
  background-image: linear-gradient(
    90deg,
    rgba(212, 20, 90, 1) 47%,
    rgba(238, 156, 167, 1) 87%
  );
`;
