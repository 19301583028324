import React from "react";
import styled from "styled-components";
import Header from "../Header";
import bg from "../Assets/Sylvia/technology-circuit-lines-shiny-banner_1017-33024.webp";

const Hero = () => {
  return (
    <div>
      <Container>
        <Header />
        <Wrapper>
          <div>TECHNOLOGY</div>
          <h1>CONFERENCE</h1>
          <p>Powered by CodeLab Set06</p>
          <h3>
            <span>Tech As A Leveller In The 21st Century</span>
          </h3>
          <Line></Line>
        </Wrapper>
      </Container>
    </div>
  );
};

export default Hero;

const Line = styled.div`
  width: 300px;
  height: 1px;
  background-color: white;
  z-index: 1;

  @media screen and (max-width: 320px) {
    width: 250px;
    height: 1px;
    background-color: white;
  }
`;

const Wrapper = styled.div`
  margin: 0;
  /* background-color: black; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: white;
  width: 100%;
  height: 100%;

  h1 {
    z-index: 111;
    margin: 0;
    font-size: 50px;
    // margin-top: 2px;
    font-weight: bold;

    @media screen and (max-width: 320px) {
      font-size: 35px;
    }
  }

  div {
    z-index: 111;
    margin: 0;
    font-size: 40px;
    font-weight: 100;
    @media screen and (max-width: 320px) {
      font-size: 25px;
    }
  }
  p {
    z-index: 111;
    margin: 0;
    font-size: 20px;
    margin-top: 5px;
  }
  h3 {
    z-index: 111;
    margin: 0;
    margin-top: 10px;
    margin-bottom: 50px;
    @media screen and (max-width: 320px) {
      font-size: 15px;
    }
  }
  span {
    z-index: 111;
  }
`;

const Container = styled.div`
  width: 100%;
  height: 100vh;
  background-image: url(${bg});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: black;
  position: relative;
  overflow: hidden;

  ::before {
    content: "";
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    position: absolute;
  }
`;
