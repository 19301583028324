import React from 'react'
import { useRoutes } from 'react-router-dom'
import Hero from './Hero/Hero'
import Register from "./Register"
import Submit from './Submit'


const Allroutes = () => {

    let element = useRoutes([
        {
            path: "/",
            element: <Hero />
        },
        {
            path: "/register",
            element: <Register />
        },
        {
            path: "/submit",
            element: <Submit />
        },
    ])
  return element
}

export default Allroutes