import React from "react";
import Footer from "./COMPONENTS/Footer";
import Main from "./COMPONENTS/Main";
import ResHeader from "./COMPONENTS/ResHeader";
import ResHero from "./COMPONENTS/ResHero";
import ResHomeScreen from "./COMPONENTS/ResHomeScreen";
import ResSolution from "./COMPONENTS/ResSolution";
import Allroutes from "./MyComps/Allroutes";
import Header from "./MyComps/Header";

const App = () => {
  return (
    <div>
      {/* <Header /> */}
      <Allroutes />
      {/* <ResHeader />
      <ResHomeScreen />
      <Footer /> */}
      {/* <Main /> */}
    </div>
  );
};

export default App;
